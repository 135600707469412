.table-ourSuppliers {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 20% 20% 20% 20% 20%;
    @include media-max(1400px) {
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
    @include media-max(1200px) {
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
    @include media-max(1000px) {
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
    @include media-max(800px) {
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
    @include media-max(650px) {
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
  }
  .header {
    @include media-max(1200px) {
      // padding-left: 45px !important;
      .table_cell {
        width: 25%;
        display: flex !important;
      }
      //   &:first-child {
      //     display: flex !important;
      //   }
      //   &:nth-child(2) {
      //     display: flex !important;
      //   }
      //   &:nth-child(3) {
      //     display: flex !important;
      //   }
      //   &:nth-child(4) {
      //     display: flex !important;
      //   }
      //   &:nth-child(7) {
      //     display: flex !important;
      //   }
      // }
    }
  }
  .table_row {
    @include media-max(1200px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 8px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        //   &:nth-child(2) {
        // display: flex !important;
        flex: 1;
        @include media-max(1000px) {
          //   flex: unset !important;
          width: 25%;
        }
        @include media-max(800px) {
          width: 25%;
        }
        @include media-max(650px) {
          //   flex: 1 !important;
          width: 25%;
        }
      }
      //   &:nth-child(3) {
      //     display: flex !important;
      //     flex: 1;
      //     @include media-max(650px) {

      //     }
      //   }
      //   &:nth-child(4) {
      //     display: flex !important;
      //     flex: 1;
      //     @include media-max(1000px) {

      //     }
      //   }
      //   &:nth-child(5) {
      //     display: flex !important;
      //     flex: 1;
      //     @include media-max(1000px) {

      //     }
      //   }
      //   &:nth-child(8) {
      //     display: flex !important;
      //     width: 70px !important;
      //   }
      //   &:nth-child(9) {
      //     display: flex !important;
      //     width: 70px !important;
      //   }
      //   display: none;
      //   padding-left: 5px;
      // }
    }
  }
  .mobile_table_wrapper {
    @include media-max(1200px) {
      display: block;
      .mobile_table_holder .mobile_table_row .row_label {
        min-width: 70px;
      }
      .mobile_table_row:nth-child(1) {
        display: none;
        @include media-max(650px) {
          display: grid;
        }
      }
      .mobile_table_row:nth-child(2) {
        display: none;
        @include media-max(1000px) {
          display: grid;
        }
      }
      .mobile_table_row:nth-child(3) {
        display: none;
        @include media-max(1000px) {
          display: grid;
        }
      }
    }
  }
}

.table-ourSuppliersProducts {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 20% 20% 20% 20% 20%;
    @include media-max(1400px) {
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
    @include media-max(1200px) {
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
    @include media-max(1000px) {
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
    @include media-max(800px) {
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
    @include media-max(650px) {
      grid-template-columns: 20% 20% 20% 20% 20%;
    }
  }
  .header {
    @include media-max(1200px) {
      // padding-left: 45px !important;
      .table_cell {
        width: 25%;
        display: flex !important;
      }
      //   &:first-child {
      //     display: flex !important;
      //   }
      //   &:nth-child(2) {
      //     display: flex !important;
      //   }
      //   &:nth-child(3) {
      //     display: flex !important;
      //   }
      //   &:nth-child(4) {
      //     display: flex !important;
      //   }
      //   &:nth-child(7) {
      //     display: flex !important;
      //   }
      // }
    }
  }
  .table_row {
    @include media-max(1200px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 8px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        //   &:nth-child(2) {
        // display: flex !important;
        flex: 1;
        @include media-max(1000px) {
          //   flex: unset !important;
          width: 25%;
        }
        @include media-max(800px) {
          width: 25%;
        }
        @include media-max(650px) {
          //   flex: 1 !important;
          width: 25%;
        }
      }
      //   &:nth-child(3) {
      //     display: flex !important;
      //     flex: 1;
      //     @include media-max(650px) {

      //     }
      //   }
      //   &:nth-child(4) {
      //     display: flex !important;
      //     flex: 1;
      //     @include media-max(1000px) {

      //     }
      //   }
      //   &:nth-child(5) {
      //     display: flex !important;
      //     flex: 1;
      //     @include media-max(1000px) {

      //     }
      //   }
      //   &:nth-child(8) {
      //     display: flex !important;
      //     width: 70px !important;
      //   }
      //   &:nth-child(9) {
      //     display: flex !important;
      //     width: 70px !important;
      //   }
      //   display: none;
      //   padding-left: 5px;
      // }
    }
  }
  .mobile_table_wrapper {
    @include media-max(1200px) {
      display: block;
      .mobile_table_holder .mobile_table_row .row_label {
        min-width: 70px;
      }
      .mobile_table_row:nth-child(1) {
        display: none;
        @include media-max(650px) {
          display: grid;
        }
      }
      .mobile_table_row:nth-child(2) {
        display: none;
        @include media-max(1000px) {
          display: grid;
        }
      }
      .mobile_table_row:nth-child(3) {
        display: none;
        @include media-max(1000px) {
          display: grid;
        }
      }
    }
  }
}
