.brands_screen {
  .table_helper {
    display: flex;
    justify-content: space-between;
  }
  .align_row {
    //width: 50%;
    form {
    }
  }
  .table_inline_form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 80%;
    width: 80%;
    gap: 20px;
    .input_holder {
      width: 100%;
    }
    p span {
    }
    .select_image_button {
      border-radius: 5px;
      padding: 5px;
      color: white;
      background: #7367f0;
      //height: 100%;
      font-size: 15px;
      text-transform: none;
    }
    .selected_image_text {
      font-size: 15px;
    }
    button {
      margin-left: 0;
    }
  }
  .table-brands {
    img {
      height: 45px;
      width: 45px;
      border-radius: 5px;
    }
    .header,
    .table_row {
      @include flex-between;
      & > .table_cell:last-child {
        @include flex-align-center;
        justify-content: flex-end;
      }
    }
    .inline_update_form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .right button {
        margin: 0;
        justify-content: center;
      }
    }
  }
  @include media-max(500px) {
    .table_helper {
      .align_row {
        flex-wrap: wrap;
        gap: 5px;
        .table_inline_form {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 100%;
          max-width: 100%;
          input,
          button {
            width: 100%;
          }
          .input_holder {
            //width: calc(100% - 65px);
          }
          button {
            font-size: 13px;
            margin-left: 0px;
          }
        }
      }
    }
    .inline_update_form {
      height: 200px;
      padding: 0;
      grid-gap: 0;
      .left {
        width: 80%;
        flex-direction: column;
        align-items: baseline !important;
      }
    }
  }
}
