.important_tooltip {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 38px;
}

.cms_banners_page {
  .table-banners {
    .header,
    .table_row {
      display: grid;
      align-items: center;
      grid-template-columns: 70px 300px 1fr 200px 70px;
      @include media-max(700px) {
        grid-template-columns: 70px 90px 1fr 100px 70px;
      }
    }
  }
  .skeleton_inner {
    .skeleton_content {
      grid-template-columns: 300px 1fr 30px 30px;
      @include media-max(700px) {
        grid-template-columns: 100px 1fr 30px 30px;
      }
    }
  }
  .table_helper {
    @include media-max(900px) {
      flex-direction: column;
      align-items: start !important;
      .align_row {
        &:nth-child(1) {
          margin-bottom: 20px;
          @include media-max(750px) {
            margin-bottom: 0;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 0;
          }
          @include media-max(550px) {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 20px 0;
          }
        }
      }
      @include media-max(750px) {
        .align_row {
          &:nth-child(2) {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 0 20px;
          }
        }
      }
    }
    .left_side {
      @include flex-align-center;
      .positions_dropdown_inner {
        width: 250px;
        @include media-max(750px) {
          width: 100%;
        }
      }
    }
  }
  .positions_dropdown_inner {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    position: relative;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.4);
    &.active {
      .positions_dropdown_outer {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .positions_dropdown_outer {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      cursor: pointer;
      svg {
        @include transition;
      }
      span {
        color: $color-text;
      }
    }
    .positions_dropdown_items_wrap {
      position: absolute;
      left: 0;
      right: 0;
      top: 110%;
      background: #fff;
      z-index: 2;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
      .positions_dropdown_item {
        padding: 8px 20px;
        color: rgba(0, 0, 0, 0.4);
        cursor: pointer;
        font-size: 13px;
        background: #fff;
        transition: all 0.2s ease;
        @include hover {
          background: $color-grey-10;
        }
        &.active {
          color: $color-text;
          background: $color-grey-10;
        }
      }
    }
  }
  .position_field {
    position: relative;
    height: 40px;
    p {
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.2s ease;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: rgba(0, 0, 0, 0.4);
      white-space: nowrap;
      transform: translate(-10px, -35px);
      font-size: 0.7rem;
    }
  }
  .dropzone_wrapper {
    margin-bottom: 50px;
    .input_file_holder {
      @include inline-bg;
      height: 200px !important;
      position: relative;
      &:hover {
        .placeholder {
          display: flex;
        }
        .text {
          opacity: 0;
        }
      }
      .placeholder {
        position: absolute;
        @include absolute-full;
        background: rgba(0, 0, 0, 0.4);
        @include flex-center;
        display: none;
        @include transition;
        svg {
          color: $color-white;
          width: 40px;
          height: 40px;
        }
      }
      .text {
        @include transition;
      }
      .delete_icon {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        color: $color-danger;
        width: 30px;
        height: 30px;
      }
    }
  }
}
